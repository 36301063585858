import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { Box, Flex, Typography } from "components/common"
import { to_currency } from "helpers/number"

const Container = styled(Flex).attrs({
  alignItems: "center",
  justifyContent: "center",
})``

const StyledText = styled(Typography).attrs({
  color: "black",
  fontSize: "12px",
})``

const InternalProductPrice = ({ product }) => (
  <Container>
    {false && product.quickShip && (
      <Box
        as="img"
        mr={1}
        src={require("images/icons/quick_ship_green.svg")}
        width="20px"
      />
    )}
    <StyledText>
      from (<strike>{to_currency(product.retail, { precision: 0 })}</strike>){" "}
      <Box
        as="span"
        color="sale"
        data-cy={`collection-product-price-${product.style_code}`}
      >
        {to_currency(product.selling, { precision: 0 })}
      </Box>
    </StyledText>
  </Container>
)

InternalProductPrice.propTypes = {
  product: PropTypes.object,
}

const mapStateToProps = () => {
  return {}
}

const ProductPrice = connect(mapStateToProps)(InternalProductPrice)

export default ProductPrice
